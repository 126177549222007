.abstract-and-image {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow items to wrap */
}

.abstract-text {
  /* ... existing styles ... */
  width: calc(100% - 220px); /* Adjust width to account for image, adjust as needed */
}

.project-image-teaser {
  /* ... existing styles ... */
  max-width: 200px; /* Adjust as needed */
  margin-left: 20px; /* Space between text and image */
}
.content-with-images {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.abstract-text {
  flex-grow: 1;
  margin-right: 20px; /* Space between text and images */
}

.project-image-teaser {
  max-width: 150px; /* Adjust size as needed */
  max-height: 150px; /* Adjust size as needed */
  border-radius: 1em;
}

.project-image {
  max-width: 100%; /* Ensures image is not wider than its container */
  height: auto; /* Maintains aspect ratio */
  border-radius: 1em; /* Rounded edges */
}

.images-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.images-container img {
  object-fit: cover;
  border-radius: 10px;
  width: calc(50% - 10px);
  margin-bottom: 15px;
  aspect-ratio: 16 / 10;
}

/* Media query for narrower screens */
@media (max-width: 600px) { /* Adjust the max-width as needed for your design */
  .images-container img {
    width: 100%; /* Images take full width of the container */
    margin-bottom: 10px; /* Adds space between stacked images */
  }
  .abstract-text, .project-image-teaser {
    width: 100%; /* Full width for text and image */
    margin-left: 0; /* Reset margin for image */
  }

  .images-container img {
    width: 100%; /* Full width for images */
    margin-bottom: 10px; /* Space between images */
  }
}
