.ui.card.PublicationCard, .ui.cards >.card.PublicationCard {
    width:365px !important;
    min-width: 365px;
}

.PublicationGrid, .PublicationGrid > .column:not(.row), .PublicationGrid > .wide.column:not(.row) {
  padding: 0;
  margin: 0 !important;

}

.PublicationGrid > .segment {
  background: none;
}

.SegmentInvisible {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
}

.PublicationsImage {
  border-radius: 1em !important;
  width: 250px !important;
  height: 182px !important;
  object-fit: cover;
  margin-right: 0 !important;
  padding: 6px !important;

}
.PublicationImageColumn {
  max-width: 250px !important;
  max-height: 182px !important;
  padding: 0 !important;
}

.wide.column:not(.row) > .PublicationsImage {
  max-height: 120px !important;
  width: 100px !important;

}

.PublicationsContentColumn, .ui.stackable.grid > .wide.column > .PublicationsContentColumn {
    border-top: none !important;
    padding: 10px !important;
}

.PublicationsTitle {
  font-weight: 600;
  margin: 0 0 5px 0 !important;
}

.PublicationsInfo {
  margin: 0 !important;
}

