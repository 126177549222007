
.ui.card.PeopleCard {
    background: #FAFAFA !important;
    width:365px !important;
    min-width: 350px;
}

.PeopleImage {
  padding: 6px;
  border-radius: 1em !important;
  width: 150px !important;
  height: 200px !important;
  object-fit: cover;
}

.ui.cards>.card, .ui.card .PeopleContent {

}

.ui.celled.grid > .column:not(.row), .ui.celled.grid > .row > .column {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
