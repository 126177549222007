/* exo-2-regular - latin */
@font-face {
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/exo-2-v20-latin-regular.40631715.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/exo-2-v20-latin-regular.40631715.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(/static/media/exo-2-v20-latin-regular.95b5d63d.woff2) format('woff2'), /* Super Modern Browsers */
       url(/static/media/exo-2-v20-latin-regular.84b52fe9.woff) format('woff'), /* Modern Browsers */
       url(/static/media/exo-2-v20-latin-regular.95096df2.ttf) format('truetype'), /* Safari, Android, iOS */
       url(/static/media/exo-2-v20-latin-regular.2fd99944.svg#Exo2) format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-v30-latin-regular.b92a5a1a.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v30-latin-regular.b92a5a1a.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(/static/media/roboto-v30-latin-regular.15d9f621.woff2) format('woff2'), /* Super Modern Browsers */
       url(/static/media/roboto-v30-latin-regular.d3907d0c.woff) format('woff'), /* Modern Browsers */
       url(/static/media/roboto-v30-latin-regular.a0d084a3.ttf) format('truetype'), /* Safari, Android, iOS */
       url(/static/media/roboto-v30-latin-regular.8681f434.svg#Roboto) format('svg'); /* Legacy iOS */
}
