.thesis-images-container img, .thesis-image {
  max-width: 100%; /* Ensures image is not wider than its container */
  height: auto; /* Maintains aspect ratio */
  max-height: 400px;
  object-fit: cover;
  border-radius: 1em;
  width: calc(60% - 10px);
  margin-bottom: 15px;
}

.images-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}
