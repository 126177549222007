.test1 {
  whiteSpace: 'pre-line';
}

.PublicationTeaserImage{
    max-height: 250px !important;
    width: auto;
    margin-left: 1rem !important;
    margin-top: 0 !important;
    object-fit: contain;
}

.PublicationAbstract {
    border: none !important;
    box-shadow: none !important;
    margin-left: 0px !important;
    margin-right: 1em !important;
    margin-bottom: 1rem !important;
    text-align: justify;
    padding-top: 0 !important;
    padding-right: 0 !important;
}

.PublicationTeaserImage > .row  {
  padding: 0 !important;
}

.image-row {
  justify-content: center !important;
  padding-bottom: 0 !important;
  width: 100%;
}

.images-container-pub {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

.images-container-pub img {
  object-fit: cover;
  border-radius: 1em;
  margin-bottom: 15px;
  width: calc(50% - 5px);
  aspect-ratio: 16 / 10;
  align-items: center;
}

.img-single {
  max-width: 385px !important;
  width: 100% !important;
}

.bibtex-box {
  font-size: 0.9em;
  line-height: 1.3em;
  white-space: pre-wrap !important;       /* Since CSS 2.1 */
}

/* Media query for narrower screens */
@media (max-width: 600px) { /* Adjust the max-width as needed for your design */
  .images-container-pub img {
    max-width: 100% !important;
    width: 100%; /* Images take full width of the container */
    margin-bottom: 10px; /* Adds space between stacked images */
    height: auto;
  }
}
