
.ui.container.TopicView {
    margin-bottom: 1em !important;
}

.TopicImage {
    background: none;
    max-height: 150px !important;
    width: auto !important;
    margin-left: 1em;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.block-description {
  text-align: justify;
}
