
.ProjectGrid, .ProjectGrid > .column:not(.row), .ProjectGrid > .wide.column:not(.row) {
  padding: 0;
  margin: 0 !important;

}

.ProjectGrid > .segment {
  background: none;
}

.SegmentInvisible {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
}

.ProjectImage {
  border-radius: 1em !important;
  width: 250px !important;
  height: 182px !important;
  object-fit: cover;
  margin-right: 0 !important;
  padding: 6px !important;

}
.ProjectImageColumn {
  max-width: 250px !important;
  max-height: 182px !important;
  padding: 0 !important;
}

.wide.column:not(.row) > .PublicationsImage {
  max-height: 120px !important;
  width: 100px !important;

}

.ProjectContentColumn, .ui.stackable.grid > .wide.column > .PublicationsContentColumn {
  border-top: none !important;
  padding: 10px !important;
}
.block-description {
  text-align: justify;
}
