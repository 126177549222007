.NewsCard {
    margin-top: 25px !important;
    background: #F9F9F9 !important;
    padding: 0px !important;
    border-radius: 1em;
    width: 365px !important;
}

.NewsTitle {
    margin-bottom: 10px !important;
}

.NewsImage {
  max-height: 100% !important;
  max-width: 100% !important;
  width: 365px !important;
  height: 225px !important;
  margin: 0 !important;
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
  object-fit: cover;
  padding: 6px !important;
}

.ui.card  :first-child {
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
}

.NewsDate {
    margin-bottom: 10px !important;
}

.NewsViewMore {
    float: right;
}
