.logo-container {
  display: flex; /* Aligns children (logos) inline */
  align-items: center; /* Centers logos vertically */
}

.logo {
  width: auto; /* Adjust the size as needed */
  height: 110px; /* Maintains the aspect ratio */
  margin-right: 0px; /* Optional: adds spacing between logos */
}
