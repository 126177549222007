.PeopleView .documentFirstHeading {
  display: none;
}

.h3_nomargin {
  margin-bottom: 0 !important;
}

div.research_interest {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.padding_top_10 {
  padding-top: 10px;
}

div.padding_top_20 {
  padding-top: 20px;
}


.compact p{
  line-height: 4px;
}

.PeopleContactGrid {
  margin-top: 3px !important;
}

.PeopleContactGrid > .row {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.PeopleImageGrid > img {
  border-radius: 1em !important;
  width: 260px !important;
  height: 350px !important;
  object-fit: cover;
}

.ic-link {
  font-size: 2.5em!important;
  color: rgb(0, 70, 149);
  margin-right: 5px;
}

.ic-link-larger {
  font-size: 2.9em!important;
  color: rgb(0, 70, 149);
  margin-right: 5px;
}

/* Regular styles - assuming these classes are something like this */
.column-1 {
  flex: 0 0 8.33333%; /* 1/12 of the container */
}

.column-11 {
  flex: 0 0 91.66667%; /* 11/12 of the container */
}

/* Media query for narrower screens */
@media (max-width: 1200px) {
  .column-1 {
    flex: 0 0 10.66667%; /* Adjusted to, say, 2/12 of the container */
  }

  .column-11 {
    flex: 0 0 89.33333%; /* Adjusted to 10/12 of the container */
  }
}

/* Media query for narrower screens */
@media (max-width: 1000px) {
  .column-1 {
    flex: 0 0 16.66667%; /* Adjusted to, say, 2/12 of the container */
  }

  .column-11 {
    flex: 0 0 83.33333%; /* Adjusted to 10/12 of the container */
  }
}
