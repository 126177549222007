@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3blejs');
  src:  url('fonts/icomoon.eot?3blejs#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3blejs') format('truetype'),
    url('fonts/icomoon.woff?3blejs') format('woff'),
    url('fonts/icomoon.svg?3blejs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-mail:before {
  content: "\ea83";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-google:before {
  content: "\ea88";
}
.icon-google2:before {
  content: "\ea89";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-youtube2:before {
  content: "\ea9e";
}
.icon-twitch:before {
  content: "\ea9f";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-xing:before {
  content: "\ead3";
}
.icon-xing2:before {
  content: "\ead4";
}
